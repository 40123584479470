import React, { Component } from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import Layout from "components/Layout/Layout";
import HeadingSpecial from "components/shared/HeadingSpecial";

import { media } from "utils/Media";

import Photos from "../components/Samsung/Photos";

import QRCode from 'images/samsung/qr.png'
import snowFlakesXs from "../images/snowflakes-mobile.svg"
import snowFlakesXl from "../images/snowflakes-desktop.svg"


const scrollToElement = require("scroll-to-element");


const SnowFlakes = styled.div`
    background: url("${snowFlakesXs}") repeat 50% 120%;
    background-size: 240%;

    @media ${media.sm} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 290%;
    }

    @media ${media.md} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 190%;
    }

    @media ${media.lg} {
        background: url("${snowFlakesXl}") repeat 50% 70%;
        background-size: 190%;
    }

    @media ${media.xl} {
        background: url("${snowFlakesXl}") no-repeat 50% 70%;
        background-size: 180%;
    }
`


const SamsungStyled = styled.div`
  width: 100%;
  padding: 2rem 0; 
  text-align: center;
  width: 90%;
  margin: 0 auto;
  .wrapper {
    @media ${media.md} {
      /* height: 550px;
      max-height: 550px; */
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      grid-gap: 2rem;
    }
    @media ${media.xl} {
      /* height: 600px;
      max-height: 600px; */
      display: grid;
      grid-template-columns: 30% 1fr; 
      grid-gap: 2rem;
    }
    .left {
      .qr {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          max-width: 370px;
        }
      }
      .title {
        margin: 2rem 0;
        p {
          margin-top: 1rem;
        }
      }
      @media ${media.md}  {
          height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
`
const Query = () => (
  <StaticQuery
    query={graphql`
      query {
        ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }
        HeaderImageXs: file(relativePath: { eq: "samsung/768.png" }) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }

        HeaderImageSm: file(relativePath: { eq: "samsung/991.png" }) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        HeaderImageXl: file(relativePath: { eq: "samsung/1920.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        Logo: file(relativePath: { eq: "frozen-logo-header.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `}
    render={(data) => <IndexPage data={data} />}
  />
);

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      // src: "https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_4yjv3qwt&flashvars[localizationCode]=en&flashvars[Kaltura.addCrossoriginToIframe]=true&&wid=0_txijjqug",
    };

    this.pageData = {
      pageName: "accesspage",
      siteName: "frozenthemusical",
      country: "uk",
      region: "emea",
    };
  }
  
  toggleModal = (e, title, src) => {
    e.preventDefault();
    
    this.setState({
      src: src,
      title: title,
      modalOpen: !this.state.modalOpen
    });
  }

  onClick = () => {
    scrollToElement("#bottomOfIframe", {
      duration: 300,
    });
  };
  render() {
    return (
      <Layout pageName="samsung" slug="samsung" title="Samsung">
        <SnowFlakes>
        <SamsungStyled>
          <div className="wrapper">
            <div className="left">
        <div className="qr">
          <img src={QRCode} alt="" />
        </div>
        <div className="title">
          {/* <h2>Book your tickets today</h2> */}
          <HeadingSpecial
            headingTag="h2"
            className="pt-2 pt-sm-3"
            title="Book your tickets to Frozen the Musical today"
            lines={false}
          />
          <p>Now playing at Theatre Royal Drury Lane in London</p>
          <p>Use your mobile phone to scan</p>
        </div>
            </div>
            <div className="right">

        <div className="embed-responsive embed-responsive-16by9">
          <iframe className='iframe' id="kaltura_player"
                              src={this.state.src}
                              width="1920" height="1280" allowFullScreen webkitallowfullscreen mozAllowFullScreen
                              allow="autoplay *; fullscreen *; encrypted-media *"
                              sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                              frameBorder="0" title="Disney Player"/>
                                    {/* <div className='embed-responsive embed-responsive-16by9'>
                    <iframe className='embed-responsive-item' id="kaltura_player"
                            src={this.state.src}
                            width="1920" height="1280" allowFullScreen webkitallowfullscreen mozAllowFullScreen
                            allow="autoplay *; fullscreen *; encrypted-media *"
                            sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                            frameBorder="0" title="Disney Player"/>
                </div> */}
        </div>
            </div>
          </div>
        </SamsungStyled>
        <Photos />
        </SnowFlakes>
        {/* <StyledLogo>
          <GatsbyImage
            image={this.props.data.Logo.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            className="img-fluid d-none d-md-block"
          />
        </StyledLogo>
        <HeadingSpecial
          headingTag="h1"
          className="pt-2 pt-sm-3"
          title="Samsung"
        />

        <RelaxedPerformancesContent>
          <Container fluid={true}>
            <Container>
              <p className="text-center color--navy mt-4 narrow">
                Set the magic free with Disney’s spectacular FROZEN. The
                award-winning musical brings to life a world of sumptuous
                beauty, humour and iconic music in a jaw-dropping production to
                melt hearts of all ages.
              </p>

              <div className="btnWrapper">
                <div>
                  <h3 className="text-center">Magical Mondays</h3>
                  <p>
                    &pound;29.50 tickets* released every Monday for that
                    week&rsquo;s performances.
                  </p>
                  <p>
                    <em>*Maximum 4 per transaction</em>
                  </p>
                </div>
                <div className="text-center">
                  <h3 className="text-center">Me+3</h3>
                  <p>
                    For a limited time, take advantage of our Me+3 offer and buy
                    4 tickets for &pound;199, valid at selected performances.
                    Buy for yourself and take 3 friends!
                  </p>
                  <p>
                    <em>
                      *This offer is valid for selected performances until 15
                      October 2023. Visit our website for full terms and
                      conditions.
                    </em>
                  </p>
                </div>
              </div>
            </Container>

            <PaginatorLine />
          </Container>
        </RelaxedPerformancesContent> */}
      </Layout>
    );
  }
}

export default Query;
