import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import styled from "styled-components";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Content = styled.div`
    text-align: center;
`

class PhotosBlock extends Component {
    constructor(props) {

        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            modalOpen: false,
        }

    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        const {isOpen, photoIndex} = this.state;

        let images = [];

        const showGallery = this.props.data.photos.edges.map((item, i) => {
            let obj = {}
            obj.src = item.node.image.childImageSharp.gatsbyImageData.images.fallback.src
            obj.imageCaption = item.node.alt
            images.push(obj)
            return (
                <Col xs={12} sm={6} lg={4} key={i} className="py-3">
                    <div
                        tabIndex={i} role="button" aria-pressed="false"
                        onClick={() => this.setState({photoIndex: i, isOpen: true})}
                        onKeyDown={() => this.setState({photoIndex: i, isOpen: true})}
                        className="w-100 p-md-2"
                    >
                        <GatsbyImage image={item.node.thumb.childImageSharp.gatsbyImageData}
                            alt={item.node.alt}
                            onClick={() => this.setState({photoIndex: i, isOpen: true})}
                            className="img-fluid"
                        />
                    </div>
                </Col>
            )
        })


        return (
            <Content className="my-3 my-md-4">

                <Container>
                    <Row className="justify-content-center">
                        {showGallery}
                    </Row>
                    <p className="smaller">@Disney, Photography by Trevor Leighton, Johan Persson and Michael Wharley</p>
                </Container>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].src}
                        nextSrc={images[(photoIndex + 1) % images.length].src}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                        imageCaption={images[photoIndex].imageCaption}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </Content>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                photos: allSamsungPhotosJson {
                    edges {
                        node {
                            id
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 1920, quality: 100)
                                }
                            }
                            thumb {
                                childImageSharp {
                                   gatsbyImageData(width: 450, quality: 100)
                                }
                            }
                            alt
                        }
                    }
                }
            }`
        }
        render={data => (
            <PhotosBlock data={data}/>
        )}
    />
)